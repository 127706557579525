
export default {
  name: "SwiperStore",

  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 30,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      imageMockup: "/assets/images/home/store-slider/laptop-mockup.png",
      slides: [
        {
          image: "/assets/images/home/store-slider/store-7.jpg",
          title: "متجر راحتي",
          paragraph:
            "متجر متخصص ومتميز بالأثاث المنزلي والاكسسوارات أكثر من ١٣ فرع منتشرة حول المملكة",
          linkStore: "https://rahatystore.com/",
        },
        // {
        //   image: "/assets/images/home/store-slider/store-1.jpg",
        //   title: "متجر قناطير",
        //   paragraph: "متجر لبيع القهوة والشوكولاته يمكنك زياته من هنا",
        //   linkStore: "https://qanateer.cafe/",
        // },
        {
          image: "/assets/images/home/store-slider/store-2.jpg",
          title: "متجر ليتل روزا",
          paragraph: "متجر متخصص في أزياء الأطفال على الطراز الأسباني",
          linkStore: "https://little-rosa.com/",
        },
        // {
        //   image: "/assets/images/home/store-slider/store-3.jpg",
        //   title: "متجر الدهناء",
        //   paragraph: "المتجر الخاص بمنتجات العلامة التجارية شاي الدهناء",
        //   linkStore: "https://aldhna.matjrah.store/",
        // },
        {
          image: "/assets/images/home/store-slider/store-16.png",
          title: "شبرا الطائف",
          paragraph: "سلسلة متاجر وفروع شبرا الطائف  للمواد الغذائية والاستهلاكية  في مدينة الطائف .",
          linkStore: "https://shubra.online/"
        },
        // {
        //   image: "/assets/images/home/store-slider/store-17.png",
        //   title: "ذا لايك",
        //   paragraph: "أحدث ستايلات الملابس الشبابية والنسائية في متجر ذا لايك",
        //   linkStore: "https://thelikesa.com/"
        // },
        {
          image: "/assets/images/home/store-slider/store-18.png",
          title: "عطار",
          paragraph: "متجر عطار يقدم أجود انواع الأغذية الطبيعية من انحاء العالم ويقدم دائما بأجود الطبيات من انحاء العالم",
          linkStore: "https://attar-sa.com/"
        },
        {
          image: "/assets/images/home/store-slider/store-4.jpg",
          title: "ويفر ديزاين",
          paragraph: "علامة تجارية شبابية تقدم تصميمات ملابس مميزة للجنسين",
          linkStore: "https://weaver-design.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-5.jpg",
          title: "لارينا بيتيت",
          paragraph: "متجر متخصص في ملابس الأطفال المميزة",
          linkStore: "https://lareinapetite.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-6.jpg",
          title: "ماجستيا",
          paragraph: "متجر يقدم مجموعة كبيرة من أجود مستحضرات التجميل",
          linkStore: "https://majestya.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-8.jpg",
          title: "مكتبة مرزا",
          paragraph:
            "مكتبة عبدالوهاب مرزا هي سلسلة مكتبات عريقة تأسست  عام ١٩٥٧م في المملكة العربية السعودية",
          linkStore: "https://mirza.com.sa/",
        },
        {
          image: "/assets/images/home/store-slider/store-9.jpg",
          title: "دكو ستور",
          paragraph: "موقع لبيع كل مستلزمات الرياضة",
          linkStore: "https://dkoo-store.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-10.jpg",
          title: "فذ العالمية",
          paragraph: "موقع لبيع الشماغ والاقلام والجلديات",
          linkStore: "https://fath-int.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-11.jpg",
          title: "كنز الصحراء",
          paragraph:
            "متجر متخصص بصناعة سبائك الذهب والألماس ويقدم تشكيلات ألماس فاخرة",
          linkStore: "http://kanzalsahra.com",
        },
        {
          image: "/assets/images/home/store-slider/store-12.jpg",
          title: "أسماك الزينة",
          paragraph: "متجر تربية أسماك الزينة ومستلزماتها",
          linkStore: "https://fishshopest.com.sa/",
        },
        {
          image: "/assets/images/home/store-slider/store-15.jpg",
          title: "اللمسة الجميلة",
          paragraph:
            "متجر متخصص ببيع العبايات النسائية بتصاميم ناعمة ومميزه، تتدمج بين الماضي والحاضر،",
          linkStore: "https://abayatallamsa.com/",
        },
        {
          image: "/assets/images/home/store-slider/store-13.jpg",
          title: "مستر بي سي",
          paragraph:
            "متجر متخصص في الكمبيوتر و الجوال و مستلزماتهم متواجدون في السوق منذ أكثر من 16 عام",
          linkStore: "https://mrpc-ksa.com/",
        },
        // {
        //   image: "/assets/images/home/store-slider/store-14.jpg",
        //   title: "كيوت كاتس",
        //   paragraph:
        //     "تجر للقطط يطمح للتكامل بتوفير جميع الماركات العالمية في مكان واحد",
        //   linkStore: "https://cutecats-pets.com/",
        // },
      ],
      slidesKW: [
        {
          image: "/assets/images/home/store-slider/kw-store-1.png",
          title: "دار حروف",
          paragraph:
            " دار نشر كويتية تأسست في عام 2018 ، تهدف لـ رفع مستوى القراءة في الكويت والوطن العربي من خلال طرح العديد من الإصدارات المتنوعة للكتاب",
          linkStore: "https://darhorof.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-2.png",
          title: "دار خطوة ",
          paragraph:
            "دار خطوة للنشر والتوزيع الكتب، دار نشر كويتية تأسست في عام ٢٠١٨، وذلك حرصًا منها على دعم القراءة.",
          linkStore: "https://khatwahkw.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-3.png",
          title: "صندوق اقرأ",
          paragraph:
            "صندوق اقرا تطبيق وموقع إلكتروني لطلب الكتب الورقية خدمة التوصيل متوفرة لجميع دول العالم متوفر أكثر من ١٠٠٠ كتاب في عدة مجالات متنوعة ",
          linkStore: "https://boxaqra.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-4.png",
          title: "مكتبة الوردي",
          paragraph:
            " مكتبة الوردي نقدم لكم ارقى و اجود الادوات المكتبية والقرطاسية وباجمل الاسعار",
          linkStore: "https://alwardibookshop.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-5.png",
          title: "عبايات اللمسة المميزة",
          paragraph:
            " عبايات اللمسة المميزة تتميز بتصاميم حصرية ومميزة تناسب المرأة السعودية والخليجية موديلات مختلفة تناسب معظم الأذواق وبأسعار مناسبة للجميع ",
          linkStore: "https://abayatalamsa.com/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-6.png",
          title: "اسماك الزينة",
          paragraph:
            "متجرك الأول في عالم تربية أسماك الزينة",
          linkStore: "https://fishshopest.com.sa/",
        },
        {
          image: "/assets/images/home/store-slider/kw-store-7.png",
          title: "ويفرز",
          paragraph:
            "ويڤر ديزاين علامة تجارية مسجله رسمياً مختصه ببيع الملابس و الاكسسوارات ذات تصميم وطابع مختلف",
          linkStore: "https://weaver-design.com/",
        },
      ],
    };
  },
  mounted() {
    if (window.innerWidth > 960) {
      this.swiperOption.autoplay = { delay: 5000 };
    }
    if (this.$i18n.locale == 'kw') {
      this.slidesKW.map(slide => {
        this.slides.push(slide)
      })
    }
  },
};
